<template>
    <div class="coupon-wrapper">
       <el-tabs :tab-position="'top'" v-model="tabBarIndex">
            <el-tab-pane v-for="(item,index) in tabBarList"  :key="index" :label="$t(item.label)">
                <template v-if="orderList.length">
                    <div class="list" v-infinite-scroll="load" :infinite-scroll-immediate="false">
                        <div class="items"  v-for="(coupon,index) in orderList" :key="index" >
                           <div class="top flex-bt">
                               <div class="left">
                                   <div class="money" :class="[coupon.is_used == 1 ? '' : '']">HK${{coupon.discount}}</div>
                                   <div class="rules">{{coupon.minimum}} Can use</div>
                               </div>
                               <div class="center">
                                   <div>{{coupon.coupon_name}}</div>
                                   <div>{{coupon.effective_start_time}} TO {{coupon.effective_end_time}}</div>
                               </div>
                               <div class="right" :class="[coupon.is_used == 1 ? 'active' : '']" @click="useCoupon(coupon.is_used)">{{coupon.is_used == 1?$t('USED'):$t('USE NOW')}}</div>
                           </div>
                           <div class="bottom">
                              {{coupon.coupon_desc}}
                           </div>
                        </div>
                       
                        <div class="coupon flex-center" @click="getCoupon">
                           <span> {{$t('GET')}}<br>{{$t('CONPON')}}</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="list-nodata">
                        <div class="coupon flex-center" @click="getCoupon">
                           <span> {{$t('GET')}}<br>{{$t('CONPON')}}</span>
                        </div>
                    </div>
                        
                    <el-empty :description="$t('NO DATA')"></el-empty>
                </template>
            </el-tab-pane>
        </el-tabs>
        <el-dialog
            :title="$t('GET CONPON')"
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="handleClose">
            <div class="coupon">
                <div class="items"  v-for="(coupon,index) in couponList" :key="index" >
                    <div class="top flex-bt">
                        <div class="left">
                            <div class="money">HK${{coupon.discount}}</div>
                            <div class="rules">{{coupon.minimum}} CAN USE</div>
                        </div>
                        <div class="center">
                            <div>{{coupon.coupon_name}}</div>
                            <div>{{coupon.release_start_time}} 至 {{coupon.release_end_time}}</div>
                        </div>
                        <div class="right"  @click="receive(index,coupon.is_used)"> {{coupon.is_used == 0 ? $t('GET NOW') : $t('USE NOW')}}</div>
                    </div>
                    <div class="bottom">
                         {{coupon.coupon_desc}}
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="dialogVisible = false">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tabBarIndex: 0,
            dialogVisible: false,
            tabBarList: [
                {
                    label: 'ALL',
                    params: {}
                },
                {
                    label: 'NO USE',
                    params: {
                        status: 1
                    }
                },
                {
                    label: 'USED',
                     params: {
                        status: 2
                    }
                },
                // {
                //     label: '已過期',
                //     params: {
                //         status: 3
                //     }
                // }
			],
            orderList: [],
            total: -1,
            page: 1,
            couponList: [],
            locale: window.localStorage.getItem('locale')|| this.$i18n.locale
        }
    },
    methods: {
         async getCouponList(page) {
            page && (this.page = 1)
            const res = await this.$http.get('/user.coupon', {
                page: this.page++,
                ...this.tabBarList[this.tabBarIndex].params
            })
            if (res.code !== 0) {
                
         let errorMessage = res.message;

            if (this.locale.toUpperCase() === 'EN') {
                if (errorMessage.includes('請先登錄')) {
                    errorMessage = 'Please login first';
                }
                // Add more translations as needed
            }

            return this.$message.error(errorMessage);
            }
            let data = res.data
            this.total = data.total
            this.orderList = [...data.list, ...this.orderList]
        },
        async getCoupon() {
            this.dialogVisible = true
            this.uniacid =window.localStorage.getItem('uniacid');
            //this.uniacid = 19;
            const res = await this.$http.get('/coupon', {
                page: 1,
                uniacid:  this.uniacid
            })
            if (res.code !== 0) {
              
        let errorMessage = res.message;

            if (this.locale.toUpperCase() === 'EN') {
                if (errorMessage.includes('請先登錄')) {
                    errorMessage = 'Please login first';
                }
                // Add more translations as needed
            }

            return this.$message.error(errorMessage);
            }
            res.data.list.forEach(item => {
                item.is_used = 0
            })
            this.couponList = res.data.list
        },
        //滚动加载
        load() {
            this.getCouponList()
        },
        useCoupon(val) {
            if (val == 0) {
                this.$router.push({name:'Home'})
            }
        },
        handleClose() {
            this.dialogVisible = false
        },
        async receive(index,is_used) {
            if(is_used == 0){
                const list = this.couponList;
                const coupon_id = list[index].coupon_id
                const res = await this.$http.post('/user.coupon/receive', {
                    coupon_id,
                })
                if (res.code !== 0) {
                    return this.$message.error(res.message);
                }
               
                this.$message({
                    message: res.message,
                    type: 'success'
                });
                list[index].is_used = 1
                this.getCouponList()
            }else{
                this.dialogVisible = false
                this.$router.push({name:'Home'})
            }
        },
    },
     watch: {
        tabBarIndex:function(val){
            this.orderList = []
            this.total = -1
            this.page = 1
            setTimeout(() => {
                this.getCouponList()
            }, 100);
        }
    }
}
</script>
<style lang="scss" scoped>
.coupon-wrapper {
    width: 100%;
    .coupon{
        width: 100%;
        height: 600px;
        /*overflow-y: scroll;*/
    }
    .items{
            margin-bottom: 30px;
            padding: 20px;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(0 0 0 / 10%);
            border-radius: 5px;
            width: 380px;
            overflow: hidden;
            display: inline-block;
            margin-right: 10px;
            .top{
                width: 100%;
                padding-bottom: 10px;
                .left{
                    .money{
                        color: rgb(255, 0, 0);
                        font-size: 27px;
                        font-weight: bold;
                        line-height: 27px;
                        &.active{
                            background-color: #515151;
                        }
                    }
                    .rules{
                        color: #333;
                        margin-top: 6px;
                    }
                }
                .center{
                    flex: 1;
                    padding: 10px;
                    div:last-child{
                        margin-top: 10px;
                    }
                }
                .right{
                    height: 30px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 13px;
                    padding-left: 11px;
                    padding-right: 11px;
                    background-color: rgb(255, 51, 0);
                    margin-left: 11px;
                    color: #ffffff;
                    line-height: 30px;
                    cursor: pointer;
                    &.active{
                        background-color: #515151;
                    }
                }
            }
            .bottom{
                border-top: 1px solid  rgb(0 0 0 / 10%);
                padding-top: 20px;
            }
        }
    .list {
        position: relative;
        padding: 30px 0 26px;
        border-bottom: 1px solid #ececec;
        overflow: auto;
        height: 600px;
        padding-right: 30px;
        .coupon{
            position: absolute;
            top: 30px;
            right: 50px;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            width: 120px;
            height: 80px;
            box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            color: rgb(255, 0, 0);
            text-align: center;
            line-height: 18px;
            cursor: pointer;
            
        }        
    }

    .list-nodata {
        position: relative;
        padding: 30px 0 26px;
        border-bottom: 1px solid #ececec;
        overflow: auto;
        height: 120px;
        padding-right: 30px;
        .coupon{
            position: absolute;
            top: 30px;
            right: 50px;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            width: 120px;
            height: 80px;
            box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            color: rgb(255, 0, 0);
            text-align: center;
            line-height: 18px;
            cursor: pointer;
            
        }        
    }
}
</style>