<template>
    <div class="order-wrapper">
        <el-tabs :tab-position="'top'" v-model="tabBarIndex">
            <el-tab-pane v-for="(item,index) in tabBarList"  :key="index" :label="$t(item.label)">
                <template v-if="orderList.length">
                    <div class="list" v-infinite-scroll="load" :infinite-scroll-immediate="false">
                        <div class="items"  v-for="(item,index) in orderList" :key="index" >
                            <div class="bd">
                                <div class="order-txt flex-bt">
                                    <span>{{$t('Time')}}: {{item.basic.data.field.order_time}}</span>
                                    <span>{{translateOrderStatus(item.status.data.field.text)}}</span>
                                </div>
                                <div class="product-content">
                                    <div class="goods-item" v-for="(child,childIndex) in item.goods.data.list" :key="childIndex">
                                        <div class="img-box">
                                            <img :src="child.goods_image" alt />
                                        </div>
                                        <div class="info-txt">
                                            <div class="title line2">{{translateGoodsName(child.goods_name)}}</div>
                                            <div class="info">{{child.goods_sku_text ? child.goods_sku_text : 'Default'}}</div>
                                            
                                            <template v-if="item.basic.data.field.order_currency === 'USD'">
                                                <div class="price">US${{child.goods_us_amount}}</div>
                                            </template>
                                            <template v-else>
                                                <div class="price">{{child.danwei + child.goods_amount}}</div>
                                            </template>
                                            <span class="num">x{{child.goods_number}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="foot">
                                <p>
                                    <template v-if="item.basic.data.field.order_currency === 'USD'">
                                        <span> US$ {{item.basic.data.field.order_amount}}</span>
                                            </template>
                                            <template v-else>
                                                <span> {{item.basic.data.field.order_amount}}</span>
                                            </template>
                                    
                                </p>
                                <div class="btn-wrapper">
                                    <template v-if="item.status.data.field.text=='待支付'">
                                        <div class="rest" @click="orderCancel(item.basic.data.field.order_sn)">{{$t('Cancel Order')}}</div>
                                        <div class="pay" @click="goDetail(item.basic.data.field.order_sn)">{{$t('PAY')}}</div>
                                    </template>
									<template v-if="item.status.data.field.text=='已支付'">
										   <div class="rest" @click="orderCancel1(item.basic.data.field.order_sn)">{{$t('Chargeback')}}</div>
									 </template>
                                    <div class="pay" @click="goDetail(item.basic.data.field.order_sn)">{{$t('Deatil')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div style="margin-top:200px"></div>
                    <el-empty :description="$t('NO DATA')"></el-empty>
                </template>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
// import translations from './translation.json'
import translations from "@/assets/translations.json";
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            tabBarIndex: 0,
            tabBarList: [
                {
                    label: 'ALL',
                    params: {}
                },
                {
                    label: 'waitPay',
                    params: {
                        order_status: 2,
                        tab: 'waitPay'
                    }
                },
                {
                    label: 'waitSend',
                    params: {
                        order_status: 4,
                        tab: 'waitSend'
                    }
                },
                {
                    label: 'waitConfirm',
                    params: {
                        order_status: 6,
                        tab: 'waitConfirm'
                    }
                },
                {
                    label: 'waitComment',
                    params: {
                        is_comment: 0,
                        tab: 'waitComment'
                    }
                },
			],
            orderList: [],
            total: -1,
            page: 1,
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
            uniacid: parseInt(window.localStorage.getItem('uniacid')),
        };
    },

    created() {},

    methods: {
        ...mapActions(['handleErrorMessage']),
        // // 获取订單统计
        // async getOrderStatistics() {
        //     const res = await uni.$http.get('/order.statistics')
        //     if (res.data.code !== 0) {
        //         if (res.data.code === 401) this.tabBarList1.forEach(item => item.count = 0)
        //         return
        //     }
        //     // let info = this.loginUserInfo
        //     let data = res.data.data
        //     this.tabBarList1.forEach(item => {
        //         if (data[item.tabCode] !== undefined) item.count = data[item.tabCode]
        //     })
        // },
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        translateOrderStatus(statusText) {
        if (this.locale === 'EN') {
            const statusTranslations = {
                '待支付': 'Pending Payment',
                '已支付': 'Paid',
                '已取消': 'Cancelled',
                '已完成': 'Completed',
                // Add more status translations here
            };
            return statusTranslations[statusText] || statusText;
        }
        return statusText;
    },
        //获取訂單列表
        async getOrderList(page) {
            page && (this.page = 1)
            // if (this.total >= this.orderList.length) return
            const res = await this.$http.get('/order', {
                page: this.page++,
                //uniacid: 19,
                uniacid: parseInt(window.localStorage.getItem('uniacid')),
                ...this.tabBarList[this.tabBarIndex].params
            })
            if (res.code !== 0) {
                return this.$store.dispatch('global/handleErrorMessage', res.message );
    }
            let data = res.data
            this.total = data.total
            this.orderList = [...data.list, ...this.orderList]
            console.log("uniacid: " + this.uniacid)
        },

        // 取消订單
		orderCancel(order_sn) {
			this._doConfirmAndRequest('確定要取消吗？', '/order/cancel', order_sn)
		},
		async orderCancel1(order_sn){
			  //let _this = this
			  //_this.$message({message: '請登錄APP取消訂單'})    
               // console.log("order_sn: " + order_sn);

                this.$confirm("確定要退款嗎", '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.applyRefund(order_sn)
                }).catch(() => {
                    this.$message({message: '已取消'})
                })


                
                



		},
        async applyRefund(order_sn){
            const res = await this.$http.get('/order/detail', {
					order_sn: order_sn
				})
				//if (res.data.code !== 0) {
				///	return this.$message.error(res.data);
				//}
				let data = res.data
				let refundList = []
				console.log(data);
				let goodsList = data.goods.data.list;
              //  console.log(goodsList);

                if (goodsList) {
					goodsList.forEach(item => {

							if (item.refund_status < 1) {
								refundList.push({
									form: {
										amount: item.goods_amount,
										reason: '',
										images: [],
										description: '',
										order_goods_id: item.order_goods_id
									},
									goods: [item]
								})
							}

					})
				}else{
					refundList.push({
						form: {
							amount: data.basic.data.field.order_amount,
							reason: '',
							images: [],
							description: '',
							order_goods_id: ''
						},
						goods: goodsList
					})
				}
				

              //  console.log("refundList")
             // console.log(refundList)

              let refund = []
				refundList.forEach(item => refund.push(item.form))
				const res2 = await this.$http.post('/order/refund', {
					order_sn: order_sn,
					refund: JSON.stringify(refund)
				})
				if (res2.data.code !== 0) {
                    this.$message({message: res2.message, type: 'success'})
                    this.getOrderList(1)
				}

        },

        //滚动加载
        load() {
            this.getOrderList()
        },

        // 提示并請求
        _doConfirmAndRequest(content, api, order_sn) {
            let _this = this
            this.$confirm(content, '提示', {
                confirmButtonText: '確定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$http.post(api, {
                    order_sn,
                }).then(res => {
                    if(res.code !== 0) {
                        return _this.$message.error(res.message);
                    }else{
                        _this.$message({message: res.message, type: 'success'})
                        _this.getOrderList(1)

                    }
                })
            }).catch(() => {
                 _this.$message({message: '已取消'})    
            })
        },

        //訂單详情页面
        goDetail(order) {
             this.$router.push({ name: 'OrderDteail', params:{order}})
        },
        
    },
    watch: {
        tabBarIndex:function(val){
            this.orderList = []
            this.total = -1
            this.page = 1
            this.getOrderList()
        }
    }
};
</script>
<style lang="scss" scoped>
.order-wrapper {
    width: 100%;
    .list {
        position: relative;
        padding: 30px 0 26px;
        border-bottom: 1px solid #ececec;
        overflow: auto;
        height: 600px;
        .items{
            margin-bottom: 30px;
        }
        .bd {
            padding-right: 40px;
            border-bottom: 1px dashed #e1e1e1;
            cursor: pointer;
            .order-txt {
                color: #282828;
                font-size: 14px;
                span:last-child {
                    color: #e93323;
                }
            }
            .product-content {
                margin-top: 20px;
                .goods-item {
                    display: flex;
                    position: relative;
                    margin-bottom: 20px;
                    .img-box {
                        width: 120px;
                        height: 120px;
                        
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .info-txt {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 500px;
                        margin-left: 24px;
                        font-size: 14px;
                        .info {
                            font-size: 12px;
                            color: #aaa;
                            margin-top: 4px;
                        }
                        .price {
                            margin-top: 15px;
                            color: #e93323;
                        }
                        .num {
                            position: absolute;
                            right: 0;
                            top: 60%;
                            transform: translateY(-50%);
                            color: #999999;
                        }
                    }
                }
            }
        }
        .foot {
            padding-top: 26px;
            padding-right: 32px;
            p {
                text-align: right;
                color: #666;
                font-size: 14px;
                span {
                    color: #E93323;
                }
            }
            .btn-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                div {
                    width: 110px;
                    height: 36px;
                    text-align: center;
                    line-height: 34px;
                    margin-left: 20px;
                    border: 1px solid #999999;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #666666;
                    cursor: pointer;
                    &.pay {
                        border-color: #E93323;
                        background: #E93323;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>