<template>
    <div class="user-wrapper">
        <search :logo="webset.logo" />
        <div class="w1200 main">
            <div class="left">
                <div class="userinfo">
                    <div class="logo">
                        <img :src="userInfo.avatar" alt="">
                    </div>
                    <div class="name">{{userInfo.nick_name}}<br /><span>{{ fb_name }}</span></div>
                    <div class="number flex-bt">
                        <p>
                            <span>{{userInfo.level}}</span>
                            <span>{{$t('My Center')}}</span>
                        </p>
                        <!-- <p>
                            <span>{{userInfo.integral}}</span>
                            <span>積分</span>
                        </p> -->
                    </div>
                </div>
                <div class="user-menu">
                    <el-tabs :tab-position="'right'" v-model="type" style="height: 600px;">
                        <el-tab-pane :label="$t('My Order')"></el-tab-pane>
                        <el-tab-pane :label="$t('My Address')"></el-tab-pane>
                        <el-tab-pane :label="$t('My Coupon')"></el-tab-pane>
                        <el-tab-pane :label="$t('Booking')" v-if="isLoggedIn &&(uniacid == 8 || uniacid == 15 || uniacid == 17 || uniacid == 19 || uniacid == 20 || uniacid == 21 || uniacid == 22 || uniacid == 27 || uniacid == 29 || uniacid == 33|| uniacid == 24|| uniacid == 43)"></el-tab-pane>
                    </el-tabs>
                </div>
            </div>

            <div class="right">

                
                <order v-if="type=='0'" />
                <address-box v-if="type=='1'" />
                <coupon v-if="type=='2'" />
                <booking v-if="type=='3'" />
                <div class="usermobile-menu">
                    <el-tabs :tab-position="'right'" v-model="type" >
                        <el-tab-pane :label="$t('My Order')"></el-tab-pane>
                        <el-tab-pane :label="$t('My Address')"></el-tab-pane>
                        <el-tab-pane :label="$t('My Coupon')"></el-tab-pane>
                        <el-tab-pane :label="$t('Booking')" v-if="uniacid == 8 || uniacid == 15 || uniacid == 17 || uniacid == 19 || uniacid == 20 || uniacid == 21 || uniacid == 22 || uniacid == 27 || uniacid == 29 || uniacid == 33|| uniacid == 24|| uniacid == 43"></el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import Search from '@/components/Search'
import Order from './components/Order'
import AddressBox from './components/Address'
import Coupon from './components/Coupon'
import Booking from './components/Booking'
export default {
    name: 'User',
    components: {Search, Order, AddressBox, Coupon, Booking},
    data() {
        return {
            uniacid:window.localStorage.getItem('uniacid'),
            //uniacid:19,
		   webset:[],
           type : '0',
           userInfo: '',
           fb_name:window.localStorage.getItem('fb_name')
        }
    },
    created(){
		 var ss = window.localStorage.getItem('webinfo');
		 var ssarray = JSON.parse(ss)
		 console.log('ss',ss);
		 console.log('ssarray.ucid',ssarray['ucid']);
		 this.webset =  ssarray
		 
		 
        this.type = this.$route.query.type
        this.getUserInfo()
		 document.title = '个人中心'
    },
    computed: {
    isLoggedIn() {
      return !!localStorage.getItem('login@token');
    }
  },
    methods: {
        async getUserInfo(e) {
            const user_id = window.localStorage.getItem('user_id') || ''
            let res = null
            if(user_id){
                res = await this.$http.get('/user/profile',{user_id})
            }
            user_id && res.code == 0 && this.$store.commit('saveUserInfo',res.data)
        },
    },
    watch: {
        '$store.state.userInfo':function(info){
            this.userInfo = info
        }
    }

}
</script>
<style lang="scss" scoped>
    .user-wrapper{
        .number{
            margin-top: 20px;
            p{
                flex: 1;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                span{
                    width: 100%;
                    text-align: center;
                    &:first-child{
                        color: #e93323;
                        font-weight: bold;
                    }
                }
            }
        }
.main{
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            .right{
                width: 75%;
                min-height: 730px;
                margin-left: 8px;
                padding-bottom: 30px;
                float: right;
                background: #fff;
                padding-left: 30px;
                padding-top: 10px;
                box-sizing: border-box;
            }
            .left{
    width: 20%;
    .userinfo    { 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 170px;
        background: #fff;
        color: #282828;
        font-size: 14px;
        .logo{
            width: 52px;
            height: 52px;
            border-radius: 50%;
            img{
                width: 52px;
                height: 52px;
                border-radius: 50%;
            }
        }
        .name{
            -webkit-line-clamp: 2;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-top: 10px;
            padding: 0 15px;
            color: #282828;
            font-size: 14px;
            text-align: center;
        }
    }
    .user-menu{
        width: 100%;
        margin-top: 10px;
        background: #ffffff;
    }
}

@media (max-width: 400px) {
    .left {
        display: none;
    }
    .right {
        width: 100%;
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;

        .usermobile-menu {
            display: block;
            display:flex;
            justify-content:space-between;
        }
    }
}

@media (min-width: 401px) {
    .right .usermobile-menu {
        display: none;
    }
}
}
    }
</style>
<style lang="css" scoped>
    .user-wrapper >>> .el-tabs--right .el-tabs__header.is-right{
        float: left;
        margin-left: 0px;
    }
    .user-wrapper >>> .el-tabs__item.is-active{
        color: #e93323;
    }
    .user-wrapper >>> .el-tabs__active-bar{
        background-color: #e93323;
    }
    .user-wrapper >>> .el-tabs__item:hover{
        color: #e93323;
    }
</style>