<template>
    <div class="address-wrapper">
        <ul>
            
            <template v-if="addressList.length">
                <li v-for="(item,index) in addressList" :key ="index">
                    <div class="name line1">{{item.contact}}</div>
                    <div class="phone">{{item.contact_number}}</div>
                    <div class="text line4">{{item.full_address}}</div>
                    <div class="edit-box">
                        <!-- <span>设為默認地址</span> -->
                        <span @click="edit(item)">{{$t('Modify')}}</span>
                        <span @click="deleteAddress(item)">{{$t('Delete')}}</span>
                    </div>
                    <div class="moren" v-if="item.is_default">{{$t('default')}}</div>
                </li>
            </template>
            <li class="addbox" @click="dialogVisible = true">
                <div class="box">
                    <img src="@/assets/images/add.png" alt />
                    <p>{{$t('add new')}}</p>
                </div>
            </li>
        </ul>
        <el-dialog
            title="ADDRESS"
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="handleClose">
            <div class="form">
    <el-form v-model="form">
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter name')" v-model="form.contact" clearable></el-input>
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter contact number')" v-model="form.contact_number" clearable></el-input>
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter shipping address')" v-model="form.address" clearable></el-input>
        <el-input class="margin-bottom-30" :placeholder="$t('Please enter house number')" v-model="form.house_number" clearable></el-input>
        <el-switch v-model="form.is_default" :active-text="$t('Set as default address')">
        </el-switch>
    </el-form>
</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">{{ $t('Cancel') }}</el-button>
            <el-button type="primary" @click="formSubmit">{{ $t('Confirm') }}</el-button>
        </span>
        </el-dialog>

    </div>
</template>
<script>
export default {
    data() {
        return {
            page: 1,
            addressList: [],
            dialogVisible: false,
            form: {
                contact: '',
                contact_number: '',
                address: '',
                house_number: '',
                is_default: true,
                province: '',
                city: '',
                district: '',
                longitude: '',
                latitude: '',
                address_id: '',
           
            },
            formCopy: '',
            addressId: '',
            locale: window.localStorage.getItem('locale')|| this.$i18n.locale,
        }
    },
    created() {
        this.getAddressList()
        this.formCopy = Object.assign({}, this.form)
    },
    methods: {
        handleClose(val){
            this.form = Object.assign({}, this.formCopy)
            this.dialogVisible = false
        },

        edit(form) {
            form.is_default = Boolean(form.is_default)
            this.form = form
            this.dialogVisible = true
        },
        

        //获取地址列表
        async getAddressList() {
            const res = await this.$http.get('/user.address', {
                page: this.page,
                size: 1000
            });

            if (res.code !== 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }

            this.addressList = res.data.list;
        },

        async formSubmit() {
            let form = this.form;
            // ... (rest of the form preparation code)

            const api = '/user.address/' + (form.address_id ? 'edit' : 'add');
            const res = await this.$http.post(api, form);

            if (res.code == 0) {
                this.dialogVisible = false;
                this.addressId = '';
                this.getAddressList();
            } else {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
        },

        async deleteAddress(address) {
            const isEnglish = this.locale.toUpperCase() === 'EN';
            const confirmText = isEnglish 
                ? 'This operation will permanently delete the address. Continue?'
                : '此操作将永久删除, 是否继续?';
            const confirmOptions = {
                confirmButtonText: isEnglish ? 'Confirm' : '確定',
                cancelButtonText: isEnglish ? 'Cancel' : '取消',
                type: 'warning'
            };

            try {
                await this.$confirm(confirmText, isEnglish ? 'Warning' : '提示', confirmOptions);
                await this.deleteAddressItem(address);
            } catch {
                this.$message({
                    type: 'info',
                    message: isEnglish ? 'Deletion cancelled' : '已取消删除'
                });
            }
        },

        async deleteAddressItem(address) {
            const res = await this.$http.post('/user.address/delete', {
                address_id: address.address_id
            });

            if (res.data.code !== 0) {
                const successMessage = this.locale.toUpperCase() === 'EN' ? 'Successfully deleted!' : '删除成功!';
                this.$message({type: 'success', message: successMessage});
            } else {
                return this.handleErrorMessage(res.message);
            }

            this.getAddressList();
        }
    }
}
</script>
<style lang="scss" scoped>
.address-wrapper {
    .margin-bottom-30{
        margin-bottom: 20px;
    }
    ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
            position: relative;
            float: left;
            width: 290px;
            min-height: 200px;
            margin-top: 30px;
            margin-right: 30px;
            box-sizing: border-box;
            padding: 22px 27px;
            border: 1px solid #eaeaea;
            .moren{
                position: absolute;
                right: 0;
                top: 0;
                width: 56px;
                height: 23px;
                line-height: 23px;
                text-align: center;
                color: #fff;
                background: #E93323;
            }
            .name{
                color: #282828;
                font-size: 16px;
            }
            .phone{
                margin-top: 14px;
                margin-bottom: 4px;
                font-size: 12px;
            }
            .text{
                color: #999999;
                font-size: 14px;
                height: 75px;
            }
            .edit-box{
                opacity: 0;
                position: absolute;
                right: 14px;
                bottom: 12px;
                color: #E93323;
                span{
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
            &.addbox{
                .box{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -25px;
                    text-align: center;
                    width: 100%;
                    color: #C8C8C8;
                    font-size: 14px;
                    img{
                        display: inline-block;
                        width: 27px;
                        height: 27px;
                    }
                }
            }
            &:hover{
                .edit-box{
                opacity: 1;
                transition: all .6s ease;
                }
            }
        }
    }
}
</style>