<template>
  <div class="order-wrapper">
    <el-tabs :tab-position="'top'" v-model="tabBarIndex">
      <el-tab-pane v-for="(item, index) in tabBarList" :key="index" :label="$t(item.label)">
        <template v-if="index == 0">
          <template v-if="bookingList.length">
            <div class="list" :infinite-scroll-immediate="false">
              <div class="items" v-for="(item, index) in bookingList" :key="index">
                <div class="bd">
                  <div class="booking-txt flex-bt">
                    <p style="padding-bottom: 10px">
                      {{$t('booking datetime')}}: {{item.book_date}}
                      <br />
                      {{$t('booking time')}}: {{item.book_time}}
                      <br />
                      <!-- {{$t('booking type')}}: {{item.book_type}}
                      <br /> -->
                      {{$t('phone no')}}: {{item.mobile}}
                      <br />
                      {{$t('name')}}: {{item.name}}
                    </p>
                    <el-button class="edit-button" @click="editBooking(item)">Edit booking</el-button>

                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div style="margin-top: 200px"></div>
            <el-empty :description="$t('NO DATA')"></el-empty>
          </template>
        </template>
        <template v-if="index == 1">
          <el-form ref="form" @submit.prevent="submitForm">
            <el-form-item :label="$t('booking datetime')">
              <select id="date-select" ref="dateselect" class="select" v-model="form.book_date">
                <template v-for="(item, index) in days" :key="index">
                  <option v-if="item.getDay() == 0" :value="item.toLocaleDateString()" disabled>
                    {{item.toLocaleDateString()}} ({{$t('rest')}})
                  </option>
                  <option v-else :value="item.toLocaleDateString()">{{item.toLocaleDateString()}}</option>
                </template>
              </select>
            </el-form-item>
            <el-form-item :label="$t('booking time')">
              <select id="time-select" ref="timeselect" class="select" v-model="form.book_time">
                <option value="10:00">10:00</option>
                <option value="12:00">12:00</option>
                <option value="14:00">14:00</option>
                <option value="16:00">16:00</option>
              </select>
            </el-form-item>
            <el-form-item :label="$t('booking type')" v-if="uniacid == 20">
              <select id="type-select" ref="typeselect" class="select" v-model="form.book_type">
                <option value="webinar">webinar</option>
                <option value="online booking">online booking</option>
              </select>
            </el-form-item>
            <el-form-item :label="$t('phone no')">
              <el-input v-model="form.mobile" :rows="1" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item :label="$t('email')">
              <el-input v-model="form.email" :rows="1" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item :label="$t('name')">
              <el-input v-model="form.name" :rows="1" style="width: 200px" readonly></el-input>
            </el-form-item>
          </el-form>
          <div @click="submitForm" class="btn">{{$t('Confirm')}}</div>
        </template>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="$t('Edit Booking')" :visible.sync="editDialogVisible" width="50%">
  <el-form :model="editForm" label-width="120px">
    <el-form-item :label="$t('booking datetime')">
      <select id="date-select" ref="dateselect" class="select" v-model="editForm.book_date">
        <template v-for="(item, index) in days" :key="index">
          <option v-if="item.getDay() == 0" :value="item.toLocaleDateString()" disabled>
            {{item.toLocaleDateString()}} ({{$t('rest')}})
          </option>
          <option v-else :value="item.toLocaleDateString()">{{item.toLocaleDateString()}}</option>
        </template>
      </select>
    </el-form-item>
    <el-form-item :label="$t('booking time')">
      <select id="time-select" ref="timeselect" class="select" v-model="editForm.book_time">
        <option value="10:00">10:00</option>
        <option value="12:00">12:00</option>
        <option value="14:00">14:00</option>
        <option value="16:00">16:00</option>
      </select>
    </el-form-item>
    <el-form-item :label="$t('phone no')">
      <el-input v-model="editForm.mobile"></el-input>
    </el-form-item>
    <el-form-item :label="$t('name')">
  <el-input v-model="editForm.name" readonly class="read-only-input"></el-input>
</el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">{{ $t('Cancel') }}</el-button>
    <el-button type="primary" @click="saveEditedBooking">{{ $t('Confirm') }}</el-button>
  </span>
</el-dialog>
  </div>
</template>


<script>
import emailjs from 'emailjs-com';



export default {
  data() {
    return {
      uniacid:parseInt(window.localStorage.getItem('uniacid'))  , // get uniacid from local storage
      tabBarIndex: 0,
      tabBarList: [
        { label: 'ALL', params: {} },
        { label: 'addBooking', params: { tab: 'addBooking' } }
      ],
      total: -1,
      page: 1,
      form: {
        date: '',
        name: window.localStorage.getItem('username'),
        mobile: '',
        book_date: '',
        book_time: '',
        book_type: '',
        email: ''
      },
      bookingList: [],
      days: [],
      booking_time_list: [],
      booking_num_list: [],
      editDialogVisible: false,
      editForm: {
        book_date: '',
        book_time: '',
        book_type: '',
        mobile: '',
        name: window.localStorage.getItem('username')
      },
      editingBookingId: null
    };
  },

  created() {
    this.getBookingList();
    const today = new Date();
    for (let i = 1; i < 15; i++) {
      const day = new Date(today.getTime() + 24 * 60 * 60 * 1000 * i);
      this.days.push(day);
    }
  },

  methods: {
    async submitForm() {
      console.log('date:', this.form.date);
      console.log('name:', this.form.name);
      console.log('mobile:', this.form.mobile);
      console.log("submitted");
      console.log('userid:', window.localStorage.getItem('user_id'));

      const uniacid = parseInt(window.localStorage.getItem('uniacid'));
      console.log('uniacid:', uniacid);
      const addform = {
        book_date: this.form.book_date,
        book_time: this.form.book_time,
        book_type: this.form.book_type,
        name: this.form.name,
        mobile: this.form.mobile,
        uniacid: uniacid,
        member_id: window.localStorage.getItem('user_id')
      };

      const api = '/booking/add';
      try {
        const res = await this.$http.post(api, addform);
        console.log('API Response:', res);

        const responseData = res;

        if (responseData.code === 0) {
          // Dispatch success message with correct message content
          await this.$store.dispatch('global/handleMessage', { message: responseData.message, type: 'success' });
        } else {
          // Dispatch error message with correct message content
          await this.$store.dispatch('global/handleMessage', { message: responseData.message });
          // Optionally, return or exit early if you don't want to proceed further
          return;
        }

              if (responseData.code == 1 || responseData.code == 0) {
                const emaillist = [
                  { uniacid: 17, service: 'service_bq3vi8c', template: 'template_gvn5jhp', api: '9AlvderpThGfNT67k' },
                  { uniacid: 19, service: 'service_vwa1m07', template: 'template_xgz76kk', api: 'H_8c6zkSntZyBRybB' },
                  { uniacid: 22, service: 'service_065c0an', template: 'template_j3nk7ua', api: 'Xa1CTNFWE-reiybrI' },
                  { uniacid: 20, service: 'service_eqgpjwl', template: 'template_ja1w4gg', api: 'aeuqp67xbb8BVViRT' },
                  { uniacid: 24, service: 'service_yac630i', template: 'template_rjjp14v', api: '4wKkS4gd0Zd4UF-Dv' },
                  { uniacid: 27, service: 'service_dxwctun', template: 'template_23tg4a9', api: 'na9x4syMmIN3PUVmw' },
                  { uniacid: 29, service: 'service_z6spsoc', template: 'template_mo7flr7', api: 'hqhqwZZcNHhqIiOLE' },
                  { uniacid: 33, service: 'service_zhkh12h', template: 'template_2mqr0tb', api: 'cuOUZQcP3XeoFq8J_' },
                  { uniacid: 43, service: 'service_fi7ac9m', template: 'template_gt20suu', api: 'FrgSZsm9yHlRrMn0d' },   
                ];

                const data = emaillist.find(item => item.uniacid === uniacid);

            if (!data) {
            console.log('No matching email service configuration found.');
            this.$store.dispatch('global/handleMessage', { message: 'Configuration not found', type: 'error' });
            return;
          }

                const emailService = data.service;
          const emailTemplate = data.template;
          const emailApi = data.api;

          try {
            console.log('SEND MAIL');
            const result = await emailjs.send(emailService, emailTemplate, {
              book_date: addform.book_date,
              book_time: addform.book_time,
              book_type: addform.book_type,
              name: addform.name,
              mobile: addform.mobile,
              email: this.form.email,
              message: responseData.message
            }, emailApi);

            this.$confirm(this.$t('Email is sent to your input field to confirm status') + '   : ' + result.text, {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'success'
          });

          } catch (error) {
            console.log('FAILED...', error.text);
            this.$store.dispatch('global/handleMessage', { message: 'Email sending failed', type: 'error' });
          }
        }
      } catch (error) {
        this.$store.dispatch('global/handleMessage', { message: 'API调用失败', type: 'error' });
        console.error('API Error:', error);
      }},

  //   async getBookingList() {
  //     const uniacid = parseInt(window.localStorage.getItem('uniacid'));
  //     const res = await this.$http.get('/booking', {
  //       params: {
  //         page: this.page,
  //         size: 1000,
  //         uniacid: uniacid,
  //         member_id: window.localStorage.getItem('user_id')
  //       }
  //     });
  //     if (res.data.code !== 0) {
  //       return this.$message.error(res.data.message);
  //     }
  //     this.bookingList = res.data.list;
  //     console.log('bookingList: ', this.bookingList);

  //     const res2 = await this.$http.get('/bookingtime', {
  //       params: { uniacid: uniacid }
  //     });
  //     if (res2.data.code !== 0) {
  //       console.log(res2);
  //       return this.$message.error(res2.data.message);
  //     }

  //     console.log('booking time: ', res2.data.list);

  //     const res3 = await this.$http.get('/booking/getnum', {
  //       params: { uniacid: uniacid }
  //     });
  //     if (res3.data.code !== 0) {
  //       console.log(res3);
  //       return this.$message.error(res3.data.message);
  //     }

  //     console.log('booking getnum: ', res3.data.list);
  //   }
  // },
  async getBookingList() {
      try {
        const currentUniacid = parseInt(window.localStorage.getItem('uniacid'));
        const response = await fetch('https://sk.skeasy.info/v1/booking?params=%7B"page":1,"size":1000,"uniacid":24,"member_id":"580340166401576962"%7D');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.code === 0 && data.data && data.data.list) {
          this.bookingList = data.data.list.filter(booking => booking.uniacid === currentUniacid && localStorage.username === booking.name);
        } else {
          console.error('Unexpected data format:', data);
        }
        console.log('Fetched booking list:', this.bookingList);
      } catch (error) {
        console.error('Error fetching data:', error);
        this.$message.error('Failed to fetch booking list');
      }
    },

      editBooking(item) {
      this.editingBookingId = item.id;
      this.editForm = {
        book_date: item.book_date,
        book_time: item.book_time,
        book_type: item.book_type,
        mobile: item.mobile,
        name: item.name
      };
      this.editDialogVisible = true;
    },

    async saveEditedBooking() {
      try {
        // Here you would typically make an API call to update the booking
        // For this example, we'll just update the local list
        const index = this.bookingList.findIndex(booking => booking.id === this.editingBookingId);
        if (index !== -1) {
          this.bookingList[index] = { ...this.bookingList[index], ...this.editForm };
          this.$message.success(this.$t('Booking updated successfully'));
          this.editDialogVisible = false;
        } else {
          throw new Error('Booking not found');
        }
      } catch (error) {
        console.error('Error updating booking:', error);
        this.$message.error(this.$t('Failed to update booking'));
      }
    }
  },
  
  watch: {
    tabBarIndex(val) {
      this.bookingList = [];
      this.total = -1;
      this.page = 1;
      this.getBookingList();
    }
  }
};
</script>



 
<style lang="scss" scoped>
.btn{
   width: 80px;
   text-align: center;
   line-height: 50px;
   margin: 24px auto 0;
   color: #fff;
   cursor: pointer;
   background: #e93323!important;
           }

           .select {
                           width: 150px;
                           border: 1px solid #d3d3d3;
                           height: 36px;
                           background: none;
                           outline: none;
                           font-weight: inherit;
                           font-family: inherit;
                           vertical-align: middle;
                           display: block;
                           line-height: 32px;
                           cursor: pointer;
                           color: #5a5a5a;
                       }
.order-wrapper {
   width: 100%;
   .list {
       position: relative;
       padding: 30px 0 26px;
       border-bottom: 1px solid #ececec;
       overflow: auto;
       height: 600px;
       .items{
           margin-bottom: 30px;
       }
       .bd {
           padding-right: 40px;
           border-bottom: 1px solid #e1e1e1;
           .booking-txt {
               color: #282828;
               font-size: 14px;
           }
           .product-content {
               margin-top: 20px;
               .goods-item {
                   display: flex;
                   position: relative;
                   margin-bottom: 20px;
                   .img-box {
                       width: 120px;
                       height: 120px;
                      
                       img {
                           display: block;
                           width: 100%;
                           height: 100%;
                           object-fit: contain;
                       }
                   }
                   .info-txt {
                       position: relative;
                       display: flex;
                       flex-direction: column;
                       justify-content: center;
                       width: 500px;
                       margin-left: 24px;
                       font-size: 14px;
                       .info {
                           font-size: 12px;
                           color: #aaa;
                           margin-top: 4px;
                       }
                       .price {
                           margin-top: 15px;
                           color: #e93323;
                       }
                       .num {
                           position: absolute;
                           right: 0;
                           top: 60%;
                           transform: translateY(-50%);
                           color: #999999;
                       }
                       .read-only-input {
  ::v-deep .el-input__inner {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #606266;
    cursor: not-allowed;
  }
}               }
               }
           }
       }
       .booking-txt {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: #282828;
      font-size: 14px;

      .edit-button {
        flex-shrink: 0;
        margin-left: 10px; // Add some space between the text and button
      }
    }
       .foot {
           padding-top: 26px;
           padding-right: 32px;
           p {
               text-align: right;
               color: #666;
               font-size: 14px;
               span {
                   color: #E93323;
               }
           }
           .btn-wrapper {
               display: flex;
               justify-content: flex-end;
               margin-top: 20px;
               div {
                   width: 110px;
                   height: 36px;
                   text-align: center;
                   line-height: 34px;
                   margin-left: 20px;
                   border: 1px solid #999999;
                   border-radius: 4px;
                   font-size: 14px;
                   color: #666666;
                   cursor: pointer;
                   &.pay {
                       border-color: #E93323;
                       background: #E93323;
                       color: #fff;
                   }
               }
           }
       }
   }
}
</style>